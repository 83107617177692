<template>
  <span style="min-height: 120px;" v-if="address">
    {{ address.street }},
    {{ address.additional_street }}
    {{ address.postal_code }}
    {{ address.city }}
    {{ address.administrative_area }},
    {{ countryFormatter(address.country) }}
  </span>
  <span v-else>{{ $t('common.misc.NA') }}</span>
</template>

<script>
import formatter from "@/mixins/formatter";

export default {
  name: "AddressInline",

  props: {
    address: {type: Object, default: () => {}}
  },

  mixins: [formatter]
}
</script>

<style scoped>

</style>